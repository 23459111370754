<template>
  <div>
    <Pane />
    <a-card class="container">
      <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 12 }" :colon="false">
        <a-card class="container">

          <div class="title">
            基本情况
          </div>

          <a-row>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="任务编号">
                <a-button block class='text ellipsis'>
                  {{ detail.number }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="任务名称">
                <a-button block class=" text ellipsis">
                  {{ detail.name }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="评估对象">
                <a-button block class='text ellipsis'>
                  {{ detail.objectName }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="评估人">
                <a-button block class='text ellipsis'>
                  {{ detail.evaluatorName }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="任务发起人">
                <a-button block class='text ellipsis'>
                  {{ detail.initiator }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="对象类型">
                <a-button block class='text ellipsis'>
                  {{ detail.objectType }}
                </a-button>
              </a-form-item>
            </a-col>
            <a-col :lg="12" :md="12" :sm="24">
              <a-form-item label="归属部门">
                <a-button block class='text ellipsis'>
                  {{ detail.deptName }}
                </a-button>
              </a-form-item>
            </a-col>
          </a-row>

          <div class="title">
            风险评估
          </div>
          <a-tabs v-model="active" :tabBarStyle="{
                margin: '0',
            }">
              <a-tab-pane key="0" tab="危险有害因素辨识"> </a-tab-pane>
              <a-tab-pane key="1" tab="风险分级管控"> </a-tab-pane>
          </a-tabs>
          <Company :list="detail.infoList" v-show="active === '0'" :showBtn="false"/>
          <Project :list="detail.infoList" v-show="active === '1'"/>
        </a-card>
        <div class="footer center">
          <a-space>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </a-card>
  </div>
</template>

<script>
import request from "@/api/request";
import organization from "@/mixins/organization";
import Company from "./components/Company.vue";
import Project from "./components/Project.vue";

function getDetail(id) {
  return request({
    url: "/office-service/quality/risk/object/task/detail/" + id,
  });
}
export default {
  name: "technicalQualityEstimateDetail",
  mixins: [organization],
  components: {
      Company,
      Project
  },

  data() {
    return {
      detail: '',
      form: this.$form.createForm(this),
      active: "0",
      loading: false,
    };
  },
  mounted() {
    const { query } = this.$route;
    const { id } = query || {};
    getDetail(id).then(res => {
      this.detail = res;
    });
  },
  methods: {
  },
};
</script>


<style lang="less" scoped>
.title {
  font-size: 14px;
  color: #1890ff;
  font-weight: bold;
  margin-bottom: 8px;
  margin-top: 8px;
  padding-left: 8px;
}
.footer {
  margin-top: 80px;
  margin-bottom: 80px;
}

.text {
    text-align: left;
}

.textarea {
    border: 1px solid #d9d9d9;
    padding: 6px 12px;
    min-height: 48px;
    border-radius: 2px;
    line-height: 1.8;
}
</style>